import React from "react";
import { useSetDanceReasonPreferenceMutation } from "services/graphql";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { trackSelectDanceReasonEventAction } from "modules/onboarding";
import { incrementStep } from "app/routes/Register/helpers";
import { Option } from "../../Option";
import { ReasonsWrapper } from "./ReasonsWrapper";
import { useAnimate } from "../useAnimate";
import {
  CATEGORY_SLUG_MAP,
  FROM_MODULE,
  REASON_SLUG_MAP,
  REASON_SLUG_TO_CATEGORIES_MAP,
} from "../../constants";

interface Props {
  reasons?: Reason[];
  isClickDisabled: boolean;
  setSelectedCategorySlugs(selectedCategorySlugs: string[]): void;
  toggleIsClickDisabled(isClickDisabled: boolean): void;
  refetchCategories(goal: string): void;
}

interface Reason {
  description: string;
  label: string;
  previewUrl: string;
  slug: string;
}

interface SetReasonResult {
  slug: string;
}

export function Reasons({
  reasons = [],
  isClickDisabled,
  setSelectedCategorySlugs,
  toggleIsClickDisabled,
  refetchCategories,
}: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { animationControls, animate } = useAnimate();

  const [setDanceReason] = useSetDanceReasonPreferenceMutation({
    onCompleted: ({ setDanceReasonPreference: setReasonResult }) => {
      const { slug }: SetReasonResult = setReasonResult;
      dispatch(
        trackSelectDanceReasonEventAction({
          danceReason: slug,
          module: FROM_MODULE,
        })
      );

      refetchCategories(
        REASON_SLUG_TO_CATEGORIES_MAP[
          slug as keyof typeof REASON_SLUG_TO_CATEGORIES_MAP
        ]
      );
      if (slug === REASON_SLUG_MAP.health) {
        setSelectedCategorySlugs([CATEGORY_SLUG_MAP.danceWorkout]);
      }
      incrementStep({ stepsToIncrement: 1, location, history });

      toggleIsClickDisabled(false);
    },
  });

  return (
    <ReasonsWrapper animationControls={animationControls}>
      {reasons.map(reason => (
        <Option
          key={reason.slug}
          label={reason.label}
          slug={reason.slug}
          description={reason.description}
          onSelect={() => {
            if (isClickDisabled) {
              return;
            }

            setSelectedCategorySlugs([]);

            toggleIsClickDisabled(true);

            setTimeout(animate, 200);

            setDanceReason({
              variables: {
                slug: reason.slug,
              },
            });
          }}
        />
      ))}
    </ReasonsWrapper>
  );
}
