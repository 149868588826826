import React from "react";
import { AnimationControls, motion } from "framer-motion";
import { Title, Subtitle } from "../../styles";
import { EntryAnimationWrapper } from "../EntryAnimationWrapper";

interface Props {
  animationControls: AnimationControls;
  children: React.ReactNode;
}

export function ReasonsWrapper({ animationControls, children }: Props) {
  return (
    <motion.div animate={animationControls}>
      <EntryAnimationWrapper>
        <Title>Dance Goal</Title>
        <Subtitle>What&#39;s the main thing you want to do?</Subtitle>
        {children}
      </EntryAnimationWrapper>
    </motion.div>
  );
}
